import React from "react";
import { useEffect } from "react";
import video1 from '../images/featuredVideo1.mp4';
import './SuccessStories.css';

function SuccessStories() {
    useEffect(() => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContext.resume();
    }, []);
    return (
        <div className="success-stories-section">
            <h3 class='success-header' style={{ textAlign: 'center'}}>Our Success Stories</h3>
            <video src={video1} width='100%' height='350px' autoPlay loop muted playsInline></video>

        </div>
    );
}

export default SuccessStories;