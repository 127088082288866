import React from 'react';
import img from '../images/gsr.jpg';
import sectionImg from '../images/canada-pic.jpg';
import './About.css';
import { Form,Col,Button,Row } from 'react-bootstrap';
import Footer from '../Components/Footer';

function About() {
    return (
        <div className='gsr-about-section'>
            <div className='gsr-about'>
         
          <div className='gsr-image'>
            <img alt='' src={img} className='gsr-div-image'></img>
          </div>
          <div className='written-section'>
         <h2> <i>Hello,</i>
         <div className='about-section-header'>
         <pre>I am Gurjeet Singh</pre>
         </div>
         </h2>
        
<span className='about-section-content'>
I'm here to be your dedicated guide in the complex world of immigration. With more than 5 years of practical experience, I have a lot of knowledge to help you reach your immigration goals. Let's work together to make your dreams of moving to a new country come true!
</span>

<span className='about-section-content'>
    <pre><strong>Education</strong></pre>
    I began my immigration journey with a firm educational grounding. My academic background includes studying computer networks at Humber College and law at Herzing College. This dual expertise provides me with a comprehensive understanding of the legal complexities fundamental to immigration processes. My education serves as the foundation upon which I offer valuable insights, guidance, and solutions in the field.
  </span>



<span className='about-section-content'>
<pre><strong>Professional Experience</strong></pre>
My immigration journey has been both rewarding and enlightening. Over the years, I've dedicated myself to mastering a wide range of immigration law facets. Whether it's handling study permits, LMIA applications, open and closed work permits, spousal visas, TRVs, TRPs, or tackling the most intricate and challenging cases, I've adeptly navigated diverse scenarios. My experience has allowed me to assist countless individuals and families in realizing their aspirations of relocating to and prospering in Canada.
</span>


<span className='about-section-content'>
    <pre><strong>Why Choose Me?</strong></pre>
    Immigration transcends mere bureaucracy; it signifies a transformative life choice. What distinguishes my approach isn't solely my academic and professional background, but also my authentic zeal for impacting lives positively. I comprehend the hurdles and ambiguities inherent in immigration endeavors, and I am committed to delivering customized, impactful, and ethical solutions designed specifically for your individual circumstances.
</span>

<span className='about-section-content'>
    <pre><strong>My Approach</strong></pre>
    I differentiate my approach through meticulous attention to detail, genuine empathy, and a steadfast dedication to keeping abreast of the dynamic shifts in immigration laws. Collaborating closely with every client, I delve into their aspirations and apprehensions, enabling me to formulate holistic strategies that significantly enhance the prospects of achieving their goals.
</span>

<span className='about-section-content'>
    <pre><strong>Let’s Connect</strong></pre>
    Regardless of whether you're a student aspiring to study in Canada, a professional exploring new opportunities, a couple aiming to reunite, or someone navigating a complex immigration case, I am here to support you at every stage of your journey. Entrusting your immigration aspirations to a knowledgeable and compassionate guide is crucial. Together, let's embark on this path. Don't hesitate to contact me for a consultation; your dreams are significant, and I am enthusiastic about assisting you in transforming them into reality.
</span>

Warm regards,

<span className='about-section-closing-comments'>
    <b>Gurjeet Singh Randhawa</b>
<b>Immigration Consultant and Advocate</b>
<b>Contact: 647-606-6812</b>
</span>


          </div>
            </div>
            <div className='gsr-about-section-two-image'>
<img alt='' src={sectionImg} width='100%' height='100%'></img>
            </div>

<div className='about-section-faq-form'>
<div className='about-section-contact-form'>
<div className='about-section-contact-form-wrap'>
    <div className='about-section-contact-form-content'>
   <span className='about-section-form-header'>Connect With Us!</span>
    <Form>
                <Row className="mb-3-about-row">
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label><span className='about-section-form-heading'>Name</span></Form.Label>
                        <Form.Control placeholder="Full Name" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label><span className='about-section-form-heading'>Email</span></Form.Label>
                        <Form.Control type="email" placeholder="Enter email" />
                    </Form.Group>


                </Row>


                <Form.Group className="mb-3-about-row" controlId="formGridMessage">
                    <Form.Label><span className='about-section-form-heading'>Message</span></Form.Label>
                    <Form.Control placeholder="Your Enquiry" />
                </Form.Group>

                <Row className="mb-3-about-row">
                    <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label><span className='about-section-form-heading'>Phone Number</span></Form.Label>
                        <Form.Control placeholder='Phone Number' />
                    </Form.Group>


                    <Form.Group as={Col} controlId="formGridZip">
                        <Form.Label><span className='about-section-form-heading'>Visa Type</span></Form.Label>
                        <Form.Control placeholder='Study/Spousal/Tourist' />
                    </Form.Group>
                </Row>



                <Button variant="primary" type="submit" className='submit-contact-form'>
                    Send Message
                </Button>
            </Form>

    </div>

</div>
    
</div>
<div class="faq">
    <span className='about-section-faq-heading'>
        Frequently Asked Questions
    </span>
  <details open>
    <summary>How can GSR Immigration help me?</summary>
    <span className='summary-content'>You might be wondering whether you really need the help of GSR Immigration Services team, when the forms and guides published on Immigration, Refugees and Citizenship Canada (IRCC) website are available to everyone.

Due to complexity and variety of Canadian immigration programs, self-assessment tools are not always reliable and will not give you the full picture. Also wrong interpretation of Canadian Immigration Laws and Regulations leads to common errors causing unnecessary delays.

Our professional and experienced specialists are there to:

Provide a professional assessment of the likelihood of your success
Provide  personalized options and solutions based on your situation
Guide you in the preparation of your documents to ensure you’re awarded the maximum number of Canadian immigration points that you are entitled to
Prepare a detailed submission letter, addressed to the appropriate IRCC Visa Office, outlining your qualifications and supporting your  candidacy
Provide assistance with explanatory letters required for your case
Advise of additional case specific documentation which should be provided for your particular situation in order to maximize the chances of success
Complete and submit full application package
Communicate with Immigration, Refugees and Citizenship Canada (IRCC) and the Canada Border Services Agency (CBSA) on your behalf (except for the direct translation of your written or spoken submissions)
Make additional written and/or oral representation to the IRCC Office and/or to the other related Canadian Government agencies, as necessary</span>
  </details>
  <details>
    <summary>How do i get started?</summary>
    <span className='summary-content'>
    Whether you’re looking to visit, study or immigrate, there are more than 67 types of programs you can apply through to gain temporary or permanent status in Canada.
<br></br>
<br></br>
<b>Step 1</b>
<br></br>
<br></br>
First of all it is important to determine your eligibility.  It will take you about 10-15 minutes to complete.
<br></br>
<br></br>
You will be asked questions about your - 

<br></br>
Education
<br></br>
Language abilities in English or French
<br></br>
Work experience
<br></br>
Age
<br></br>
Nationality
<br></br>
Family Members
<br></br>
Arranged Employment in Canada
<br></br>
Adaptability
<br></br>
Income and/or New Worth


<br></br>
<br></br>
<b>Step 2</b>
<br></br>
<br></br>

Next our team will review your profile, and you’ll receive invitation to Initial Consultation, if you qualify for one of the programs. Initial Consultation gives us an opportunity to review your situation in detail to determine the available courses of action and create personalized immigration strategy.

If you do not qualify at the moment, it will be still a good idea to proceed with consultation to review the steps you should take to become eligible in near future.
    </span>
  </details>
  <details>
    <summary>Do i need to take language test?</summary>
    <span className='summary-content'>
    Canada has two official languages: English and French. You may need to provide proof of your language skills in either language depending on the program requirements. Canadian Language Benchmarks (CLB) is the nationally recognized standard for assessing the English language proficiency of adults. Minimum requirements may differ depending on the program. For example: For Federal Skilled Worker program you must meet a minimum of CLB 7.
<br></br>
<br></br>
These are general guidelines only. Meeting minimum requirements does not guarantee invitation to apply, since you will be assessed against other factors such as age, education, work experience and etc. During initial consultation, immigration consultant will discuss language score required  for  your case considering a number of other factors impacting your overall score. International students may or may not require proof of language skills to gain acceptance to Canadian school, college or university.
<br></br>
<br></br>
You may want to consider taking IELTS preparation online course to obtain higher results on exam. By taking this online course prepared by IELTS professionals, you get everything you need to succeed, accessible online, anywhere, anytime.
Please note your test results must be less than two years old when you submit your application for permanent residence, and you can take only one of the approved language tests:
<br></br>
<br></br>
<a className='about-section-faq-links' href='http://www.celpiptest.ca/'>CELPIP: Canadian English Language Proficiency Index Program
</a>
<br></br>
<a className='about-section-faq-links' href='https://ielts.org/'>IELTS: International English Language Testing System
</a>
<br></br>
<a href='http://www.francais.cci-paris-idf.fr/tef-canada/' className='about-section-faq-links'>TEF: Test d’évaluation de français</a>

     </span>
  </details>
</div>
</div>

<Footer/>
          
        </div>
    );
}
export default About;