import React from "react";
import './ServicesCard.css';
import img from '../images/card-img.png';

function ServicesCard(){
    return (
        <div className="services-card">
          <div>
          <span className="services-card-heading"> Our Services </span>
         <div className="services-card-info">
            <span>LMIA</span>
            <span>Super Visa</span>
            <span>Spousal Work Permit</span>
            <span>Work Permit</span>
            <span>Study Permit</span>
            <span>Spousal Sponsorship</span>
            <span>Inadmissibility</span>
         </div>
          </div>
          <div className="service-card-image">
<img src={img} alt=""></img>
          </div>
        </div>
    );
}

export default ServicesCard;