import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className='footer'>

<div class="footer-row">
    <div class="info2">
            <p>Study Visa</p>
            <p>Spousal Visa</p>
            <p>Sponsorship Visa</p>
            
    </div>
    <div class="info1">
        <p>Opened On Monday - Saturday: 10am to 7pm</p>
    </div>
    <div class="info3">
    <h3>Contact Us</h3>  
    <br></br>      
        <p>+16478016812</p>
        <p>Info.acecounselling@gmail.com</p>
        <p>gsrimmi@gmail.com</p>
    </div>
    <div class="info4">
        <h3>Location</h3>
        <br></br>
        <p>2250 Bovaird Dr E Unit 403,</p>
        <p>Brampton, ON L6R 0W3</p>
        <p>India Office: Majhola,Uttar Pradesh,IN</p>
    </div>
</div>

        </div>
    );
}

export default Footer;