import React from "react";
import './SpousalSponsorship.css'
import AppointmentCard from "./AppointmentCard";
import ServicesCard from "./ServicesCard";
import Footer from '../Components/Footer';
import spouse from '../images/spouse.jpg'

function SpousalSponsorship(){
    return (
        <div className="spousal-sponsorship">
              <div className="spousal-sponsorship-first-image">
         <span className="spousal-sponsorship-heading">
         Sponsor your partner 
         </span>
                <img src={spouse} alt="" className="spousal-sponsorship-first-img"></img>
             
             </div>
      
            <span>
            <b>Spousal sponsorship</b> through the Immigration, Refugees and Citizenship Canada (IRCC) refers to the process by which a Canadian citizen or permanent resident can sponsor their spouse or common-law partner to become a permanent resident of Canada. The spousal sponsorship program is designed to reunite families by allowing eligible Canadian citizens or permanent residents to sponsor their foreign national spouse or partner to come and live in Canada.
<br></br><br></br>
To be eligible for <b>spousal sponsorship</b>, both the sponsor and the sponsored person must meet certain criteria set by IRCC. These criteria typically include demonstrating a genuine and ongoing relationship, meeting minimum income requirements to ensure the sponsor can financially support the sponsored person, and meeting other legal requirements.
<br></br><br></br>
The process generally involves the sponsor submitting an application to IRCC, including forms and supporting documents to prove the authenticity of the relationship. The sponsored person would also need to submit their own application and undergo medical and security checks.
<br></br><br></br>
The <b>spousal sponsorship</b> process can vary in terms of processing times and requirements, and it’s important to consult the official IRCC website or seek professional advice for the most up-to-date and accurate information before initiating the sponsorship process. Keep in mind that immigration policies and procedures can change over time.

            </span>
            <AppointmentCard/>
            <ServicesCard/>
            <Footer/>
        </div>
    );
}

export default SpousalSponsorship;