import React from 'react';
import img1 from '../images/image-4.png';
import img2 from '../images/image-3.png';
import img3 from '../images/oimage-3.png';
import img4 from '../images/circle-1.png';
import img5 from '../images/omage-2.png';
import video from '../images/officeVideo1.mp4';
import Row from 'react-bootstrap/Row';
import { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import './Experience.css';

function Experience() {
    useEffect(() => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContext.resume();
    }, []); 
    return (
        <div className='cont'>
            <br></br>

            <h3 style={{ textAlign: 'center',fontWeight:'600' }}>Our Experience</h3>

            <br></br>
            <div className='images'>
                <Row>
                    <Col className='image1'>
                        <img className='img1' src={img1} alt='first'></img>
                        <h4>6+</h4>
                        <p>Years of combined industry experienced team.</p>
                    </Col>
                    <Col className='image1'>
                        <img className='img1' src={img2} alt='second'></img>
                        <h4>15+</h4>
                        <p>Countries offered as immigration destinations.</p>
                    </Col>
                    <Col className='image1'>
                        <img className='img1' src={img3} alt='third'></img>
                        <h4>50+</h4>
                        <p>Residency and non-residency visa options for skilled, Business and investor categories.</p>
                    </Col>
                    <Col className='image1'>
                        <img className='img1' src={img4} alt='fourth'></img>
                        <h4>1000+</h4>
                        <p>Immigration Executed</p>
                    </Col>
                    <Col className='image1'>
                        <img className='img1' src={img5} alt='fifth'></img>
                        <h4>Since 2015</h4>
                        <p>Over 7 years of Exceptional Service.</p>
                    </Col>

                </Row>
            </div>

            <video src={video} width='100%' height='500px' autoPlay loop muted playsInline></video>

        </div>
    );
}

export default Experience;