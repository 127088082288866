import React from 'react';
import { useEffect } from 'react';
import './Slider.css';
import img1 from '../images/services-background.jpg';
import img2 from '../images/1.jpg';
import img3 from '../images/2.jpg';
import img4 from '../images/3.jpg';

function Slider() {

    useEffect(() => {
        // call api or anything
        console.log("loaded");
        slider();
        const img = document.querySelector("slider-card-image");
    console.log(img);
        if(img){
        img.addEventListener("click",()=>{
            console.log("hello");
        })
    }
     });
    
const slider = () => {
    const slideRef = document.createElement("div");
    const loadingProgress = 0;

    /*
    const handleClickNext = () => {
        let items = slideRef.querySelectorAll(".item");
        slideRef.appendChild(items[0]);
    }

    const handleClickPrev = () => {
        let items = slideRef.querySelectorAll(".item");
        slideRef.prepend(items[items.length-1]);
    }
    */

    const container = document.querySelector(".slider-container");

    const loadbar = document.createElement("div");
    loadbar.classList.add("loadbar");
    loadbar.style.width = `${loadingProgress}%`;

    container.appendChild(loadbar);
    container.appendChild(slideRef);

    
  }
    return (
<div class='slider-container'>
    <div class='item'>
        <img src={img1} alt='' class='item-image'></img>
    </div>
    <div class='slider-card'>
    <img src={img2} alt='' class='slider-card-image'></img>
        <img src={img3} alt='' class='slider-card-image'></img>
        <img src={img4} alt='' class='slider-card-image'></img>
         </div>
</div>
    );
}

export default Slider;