import React from 'react';
import './SocialMedia.css';
import img1 from '../images/instagram-icon.png';
import img2 from  '../images/twitter-icon.png';
import img3 from '../images/facebook-icon.png';
import img4 from '../images/linkedin-icon.png';

function SocialMedia() {
//https://www.instagram.com/gurjeetrandhawarcic/?igshid=OGQ5ZDc2ODk2ZA%3D%3D

//https://www.tiktok.com/@gurjeetrandhawarcic?_t=8Vp4teGLkg5&_r=1


    return (
        <div class='social'>
            <span class="social-header">Connect With Us On Social Media !</span>
           <div class="social-icons">
               <a class ='image-a' href='https://www.instagram.com/gurjeetrandhawarcic/'>
               <img src={img1} alt='' class="social-image"></img>
               </a>
               <a class ='image-a' href='https://www.facebook.com/13Randhawa?mibextid=LQQJ4d'>
               <img src={img2} alt='' class="social-image"></img>
               </a>
                
                <a class ='image-a' href='https://www.facebook.com/13Randhawa?mibextid=LQQJ4d'>
                <img src={img3} alt='' class="social-image"></img>
                </a>
               <a class ='image-a' href='https://www.instagram.com/gurjeetrandhawarcic/'> <img src={img4} alt='' class="social-image"></img></a>
            </div>
        
            </div>
    );
}

export default SocialMedia;