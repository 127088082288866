import React from "react";
import AppointmentCard from "./AppointmentCard";
import './StudyPermit.css'
import ServicesCard from "./ServicesCard";
import Footer from '../Components/Footer';
import img1 from '../images/studypermit1.jpg';
import img2 from '../images/studypermit2.jpg';

function StudyPermit(){
    return (
        <div className="StudyPermit">
            <div className="study-permit-image">
                <img src={img1} alt="" className="study-permit-img"></img>
            <span className="study-permit-heading">Study Permit</span>
            </div>
            <span className="study-permit-text">
            The study permit is a document issued to allow foreign nationals study at designated learning institutions (DLI) in Canada.
<br></br>
<br></br>
While studying in Canada under a study permit you must:
<br></br>
<br></br>
<ol>
    
<li>1. Always be enrolled at a DLI</li>
<li>2. Make progress towards completing your program</li>
<li>3. Respect any conditions listed on your study permit</li>
<li>4. Stop studying if you no longer meet the requirements and</li>
<li>5. Leave Canada when your permit expires</li>
</ol>
<br></br>
Depending on your case, there may be conditions on your study permit such as:
<br></br><br></br>
<ol>
    <li>1. The level of studies you can attend</li>
    <li>2. If you are allowed to work in Canada</li>
    <li>3. If you can’t travel within Canada</li>
    <li>4. The date you must stop studying</li>
</ol>

<div className="study-permit-note">
<b>Note:</b> Your study permit is not a visa. It alone doesn’t allow you to enter Canada. You may also need a temporary resident visa or an electronic travel authorization (eTA).

</div>
<br></br><br></br>
<b>HOW LONG CAN YOU STAY IN CANADA WITH A STUDY PERMIT?</b>

<br></br>
<br></br>
A study permit is usually valid for the length of your study program, plus an extra 90 days. This extra time lets you prepare to leave Canada or apply to extend your stay.
<br></br>
If you can’t finish your courses before the date on your permit, you must apply to extend your stay as a student.
If you finish your studies early, your permit will stop being valid 90 days after you complete your studies (no matter what day is printed on the study permit).You have completed your studies either
on the date you get the first notification from your school (such as an email, letter, transcript, etc.) or
when you get your degree, diploma or certificate.
<br></br>
You must prove provide proof of the date you got one of these.
<br></br>
<div className="study-permit-note">
    
<b>Note:</b> If you can’t prove the date you were first notified by your school, we will use the earliest issue date on the document. We may need to confirm this date with your school.

</div>
            </span>
            <AppointmentCard/>
            <ServicesCard/>
            <div className="study-permit-end-image">
                <span className="study-permit-end-image-text"> Get Graduated in Canada!</span>
                <img src={img2} className="study-permit-end-img" alt=""></img>
            </div>
            <Footer/>
        </div>
    );
}

export default StudyPermit;