import React from "react";
import AppointmentCard from "./AppointmentCard";
import ServicesCard from "./ServicesCard";
import Footer from '../Components/Footer';
import spouse from '../images/spouse.jpg';
import './SpousalWorkPermit.css';

function SpousalWorkPermit(){
    return (
        <div className="spousal-work-permit">
        <div className="spousal-work-permit-first-image">
         <span className="spousal-work-permit-heading">
         Work and Live with your partner 
         </span>
                <img src={spouse} alt="" className="spousal-work-permit-first-img"></img>
             
             </div>
        <span>
        Spousal Open Work Permit (SOWP) is a type of work permit issued by Immigration, Refugees and Citizenship Canada (IRCC). It allows the spouse or common-law partner of certain temporary foreign workers or international students in Canada to work in Canada while their partner’s study or work permit is still valid.

The Spousal Open Work Permit is considered “open” because it is not tied to a specific employer, which means the holder of this permit can work for any employer in Canada. This provides greater flexibility and opportunities for the spouse or partner to find employment and contribute to the family’s income.

To be eligible for a Spousal Open Work Permit, the main applicant (the person on a study or work permit in Canada) must fall under certain categories such as:

International students in a program of study in Canada.
Temporary foreign workers in a skilled or semi-skilled occupation.

The length of time you are legally responsible for the person you sponsor varies based on the type of family member you are sponsoring, and is either 3 or 10 years for non-residents of Quebec. Quebec has different undertaking length.
        </span>

        <AppointmentCard/>
            <ServicesCard/>
            <Footer/>
        </div>
    );
}

export default SpousalWorkPermit;