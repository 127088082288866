import ControlledCarousel from './Carousel';
import React from 'react'
import Cards from './Cards.js';
import Information from './Information';
import Experience from './Experience';
import Forms from './Forms';
import Footer from './Footer';
import SocialMedia from './SocialMedia';
import Services from './Services';
import Slider from './Slider';
import SuccessStories from './SuccessStories';
import QuiltedImageList from './SuccessCarousel.js';

function Homepage() {
    return (
        <div>
            <ControlledCarousel />
            <Cards />
            <br></br>
            <Services/>
            <br></br>
            <Information />
            <br></br>
            <Experience />
            <br></br>
            <br></br>
            <Forms />
            <br></br>
            <Slider/>
            <br></br>
            <SocialMedia/>
            <br></br>
            <SuccessStories/>
            <br></br>
          <QuiltedImageList/>
          <br></br>
            <Footer />
        </div>
    )
}

export default Homepage;
