import React from "react";
import AppointmentCard from "./AppointmentCard";
import ServicesCard from "./ServicesCard";
import Footer from '../Components/Footer';
import specificpermit from '../images/specificworkpermit.jpg';
import './SpecificWorkPermit.css';


function SpecificWorkPermit(){
    return (
        <div className="specific-work-permit">
        <div className="specific-work-permit-first-image">
         <span className="specific-work-permit-heading">
         Specific Work Permit 
         </span>
                <img src={specificpermit} alt="" className="specific-work-permit-first-img"></img>
             
             </div>
             <span>
             <b>What is an employer-specific work permit ?</b>
<br></br><br></br>
<b>LMIA (Labour Market Impact Assessment):</b> An LMIA is a document issued by Employment and Social Development Canada (ESDC), which is a department of the Canadian government. It assesses the impact of hiring a foreign worker on the Canadian labor market. Essentially, it’s a way for the government to ensure that hiring a foreign worker won’t negatively affect Canadian workers’ job opportunities and wages. To get a positive LMIA, the employer usually needs to prove that they’ve made sufficient efforts to hire a Canadian citizen or permanent resident and that hiring a foreign worker is necessary due to a lack of available qualified Canadians.
<br></br><br></br>
<b>Work Permit:</b> A work permit is a document that allows a foreign national to work in Canada legally for a specific employer and duration. There are different types of work permits, and the requirements for obtaining one can vary depending on factors such as the job offer, the worker’s qualifications, and the specific program under which they are applying.
<br></br><br></br>
<b>IRCC (Immigration, Refugees and Citizenship Canada):</b> IRCC is the federal government department responsible for immigration, refugee, and citizenship matters in Canada. They oversee the processes related to immigration, including work permits, permanent residency, citizenship, and more.
<br></br><br></br>
In the context of an LMIA work permit, the process generally involves the following steps:
<br></br><br></br>
<b>Employer Applies for LMIA:</b> The Canadian employer who wants to hire a foreign worker applies to ESDC for an LMIA. They need to provide detailed information about the job offer, the efforts made to hire Canadians, and other relevant details.
<br></br><br></br>
<b>ESDC Assesses the Application:</b> ESDC reviews the application to determine if hiring a foreign worker will have a positive or negative impact on the Canadian labor market. They consider factors such as the job market conditions, the employer’s efforts to hire Canadians, and the wages and working conditions offered.
<br></br><br></br>
<b>Positive LMIA Issued:</b> If ESDC is satisfied that hiring a foreign worker is justified, they issue a positive LMIA to the employer.
<br></br><br></br>
<b>Foreign Worker Applies for Work Permit:</b> With a positive LMIA in hand, the foreign worker can apply for a work permit to IRCC. The work permit application will require documentation such as the positive LMIA, a job offer letter, and the worker’s qualifications.
<br></br><br></br>
<b>IRCC Processes Work Permit Application: </b>IRCC reviews the work permit application and ensures that the foreign worker meets the eligibility criteria. If approved, a work permit will be issued, specifying the employer, job details, and duration of the work permit.
<br></br><br></br>
<div className="specific-work-permit-note">
It’s important to note that immigration policies and procedures can change over time, so it’s always a good idea to check the official IRCC website or consult with an immigration professional for the most up-to-date information and guidance.

</div>
             </span>
             <AppointmentCard/>
            <ServicesCard/>
            <Footer/>
       </div>
    );
}

export default SpecificWorkPermit;