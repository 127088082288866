import React, { useEffect } from 'react';
import './Carousel.css';
import video1 from '../images/carousel-video-1.mp4';
import video from '../images/carousel-video.mp4';

function ControlledCarousel() {


  useEffect(() => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    audioContext.resume();
  }, []);


  return (
    <div className='carsousels'>
      <video

        src={video}
        type="video/mp4"
        autoPlay
        muted
        loop
        id="myCarouselVideo"
        playsInline

        className="myCarouselVideo"
      />
      <video

        src={video1}
        type="video/mp4"
        autoPlay
        muted
        loop
        id="myVideo"
        playsInline

        className="myVideo"
      />
    </div>
  );
}

export default ControlledCarousel;
