import Navbars from './Components/Navbar'
import Homepage from './Components/Homepage';
import { Routes,Route } from 'react-router-dom';
import About from './pages/about';
import Contact from './pages/contact';
import LMIA from './pages/LMIA';
import StudyPermit from './pages/StudyPermit';
import SuperVisa from './pages/SuperVisa';
import SpousalSponsorship from './pages/SpousalSponsorship';
import SpousalWorkPermit from './pages/SpousalWorkPermit';
import SpecificWorkPermit from './pages/SpecificWorkPermit';
import FAQ from './pages/FAQ';

function App() {
  return (

    <div>
  
<Navbars />
<Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path='/FAQ' element={<FAQ/>}></Route>
    <Route path='/LMIA' element= {<LMIA/>}></Route>
    <Route path='/StudyPermit' element={<StudyPermit/>}></Route>
    <Route path='/SuperVisa' element={<SuperVisa />}></Route>
    <Route path='/SpousalWorkPermit' element={<SpousalWorkPermit />}></Route>
    <Route path='/SpousalSponsorship' element={<SpousalSponsorship />}></Route>
    <Route path='/SpecificWorkPermit' element={<SpecificWorkPermit />}></Route>
      </Routes>
    
     </div>



  );
}

export default App;
