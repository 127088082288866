import React from "react";
import Footer from '../Components/Footer';
import './FAQ.css';
import AppointmentCard from './AppointmentCard';

function FAQ(){
   return (
    <div className="faq">
        <div className="faq-heading-section">
            <div className="faq-heading">
            Frequently Asked Questions
            </div>
            <div className="faq-sub-heading">
                Get answer to your all queries.
            </div>
        </div>
        <div className="faq-content">
            <div className="faq-content-first-section">
            <details open className="faq-detail">
            <summary className="faq-detail-summary">Who is Authorized Representative ?</summary>
                <span className="faq-detail-content">
                Only some people are allowed to legally represent you on an immigration application. They are called Authorized Representatives.
<br></br><br></br>
You may choose to use an authorized representative to act on your behalf for immigration, citizenship or temporary residence applications with:
<br></br><br></br>
Immigration, Refugees and Citizenship Canada,
Immigration and Refugee Board of Canada, or
Canada Border Services Agency.
<br></br><br></br>
GSR Immigration is Canadian Immigration Consultant, a member of Immigration Consultants of Canada Regulatory Council in good standing and is authorized by the Canadian government to represent your interests in all immigration matters.
<br></br><br></br>
Be aware, if you choose a compensated representative who isn’t authorized, your application may be returned to you or refused.
Choose your representative carefully. 
<br></br>
Checkout: 
<br></br>
<a className="faq-links" href="http://www.cic.gc.ca/english/department/media/multimedia/video/disappearing-act/disappearing-act.asp">http://www.cic.gc.ca/english/department/media/multimedia/video/disappearing-act/disappearing-act.asp</a>
 
                </span>
                
                </details>
                <details className="faq-detail">
                    <summary className="faq-detail-summary">How can GSR Immigration help me?</summary>
               <span className="faq-detail-content">
               You might be wondering whether you really need the help of GSR Immigration team, when the forms and guides published on Immigration, Refugees and Citizenship Canada (IRCC) website are available to everyone.
               <br></br><br></br>
Due to complexity and variety of Canadian immigration programs, self-assessment tools are not always reliable and will not give you the full picture. Also wrong interpretation of Canadian Immigration Laws and Regulations leads to common errors causing unnecessary delays.
<br></br><br></br>
Our professional and experienced specialists are there to:
<br></br><br></br>
<ul className="faq-ul">
<li>1. Provide a professional assessment of the likelihood of your success
</li>
<li>2. Provide  personalized options and solutions based on your situation
</li>
<li>3. Guide you in the preparation of your documents to ensure you’re awarded the maximum number of Canadian immigration points that you are entitled to
</li>
<li>4. Prepare a detailed submission letter, addressed to the appropriate IRCC Visa Office, outlining your qualifications and supporting your  candidacy
</li>
<li>5. Provide assistance with explanatory letters required for your case
</li>
<li>6. Advise of additional case specific documentation which should be provided for your particular situation in order to maximize the chances of success
</li>
<li>7. Complete and submit full application package
</li>
<li>8. Communicate with Immigration, Refugees and Citizenship Canada (IRCC) and the Canada Border Services Agency (CBSA) on your behalf (except for the direct translation of your written or spoken submissions)
</li>
<li>9. Make additional written and/or oral representation to the IRCC Office and/or to the other related Canadian Government agencies, as necessary
</li>
</ul>
<br></br><br></br>
While our team is working on your application, you can focus on improving professional and language skills to arrive prepared.
               </span>
                </details>
                <details className="faq-detail">
                    <summary className="faq-detail-summary">How do i get started ?</summary>
                    <span className="faq-detail-content">
                    Whether you’re looking to visit, study or immigrate, there are more than 67 types of programs you can apply through to gain temporary or permanent status in Canada.
<br></br><br></br>
First of all it is important to determine your eligibility. It will take you about 10-15 minutes to complete.
<br></br><br></br>
You will be asked questions about your :
<br></br><br></br>
<ul className="faq-ul">

<li>1. Education</li>
<li>2. Language abilities in English or French</li>
<li>3. Work experience</li>
<li>4. Age</li>
<li>5. Nationality</li>
<li>6. Family Members</li>
<li>7. Arranged Employment in Canada</li>
<li>8. Adaptability</li>
<li>9. Income and/or New Worth</li>
</ul>
                    </span>
                </details>
            </div>
            <div>
                <details open className="faq-detail">
                    <summary className="faq-detail-summary">Do i need to take a language test?</summary>
                <span className="faq-detail-content">
                Canada has two official languages: English and French. You may need to provide proof of your language skills in either language depending on the program requirements. Canadian Language Benchmarks (CLB) is the nationally recognized standard for assessing the English language proficiency of adults. Minimum requirements may differ depending on the program. For example: For Federal Skilled Worker program you must meet a minimum of CLB 7.
                <br></br><br></br>
These are general guidelines only. Meeting minimum requirements does not guarantee invitation to apply, since you will be assessed against other factors such as age, education, work experience and etc. During initial consultation, immigration consultant will discuss language score required  for  your case considering a number of other factors impacting your overall score. International students may or may not require proof of language skills to gain acceptance to Canadian school, college or university.
<br></br><br></br>
You may want to consider taking IELTS preparation online course to obtain higher results on exam. By taking this online course prepared by IELTS professionals, you get everything you need to succeed, accessible online, anywhere, anytime.
Please note your test results must be less than two years old when you submit your application for permanent residence, and you can take only one of the approved language tests:
<br></br><br></br>
CELPIP: Canadian English Language Proficiency Index Program
<br></br><br></br>
IELTS: International English Language Testing System
<br></br><br></br>
TEF: Test d’évaluation de français
                </span>
                </details>
                <details className="faq-detail">
                    <summary className="faq-detail-summary">Do you guarantee a Visa Approval?</summary>
                <span className="faq-detail-content">
                Please note only Immigration, Refugees and Citizenship (IRCC) officers can decide whether or not to issue a visa. If someone “guarantees” a visa, that should immediately raise a concern – don’t be a victim of scam. <a className="faq-links" href="http://www.cic.gc.ca/english/resources/publications/fraud.asp">http://www.cic.gc.ca/english/resources/publications/fraud.asp</a>
<br></br><br></br>
While authorized representatives cannot guarantee approval of your application, our team of professionals works very hard on each detail to maximize your chances of success. We will help you to make informed decision about the most suitable type of application customized to your circumstances.
                </span>
                </details>
                <details className="faq-detail">
                    <summary className="faq-detail-summary">What can prevent me from coming to Canada?</summary>
                <span className="faq-detail-content">
                Some people are not allowed to enter Canada. The definition used by Immigration, Refuges and Citizenship Canada is “inadmissibility“.
There are many reason, why you may be refused entry to Canada:
<br></br><br></br>
you are a security risk,
you have committed human or international rights violations,
you have been convicted of a crime, or you have committed an act outside Canada that would be a crime,
you have ties to organized crime,
you have a serious health problem,
you have a serious financial problem,
you lied in your application or in an interview,
you do not meet the conditions in Canada’s immigration law, or
one of your family members is not allowed into Canada.
If you there is  a valid reason why you have to travel to Canada, you may be issued a temporary resident permit. Please note a Canadian immigration officer will decide if you can enter Canada when you apply for a visa, an Electronic Travel Authorization (eTA), or when you arrive at a port of entry.
<br></br><br></br>
Don’t wait until your visa is refused. If you or your dependent family member has an inadmissibility condition, contact us today to discuss your option
                </span>
                </details>
            </div>
        </div>
        <AppointmentCard/>
        <br></br>
        <br></br>
        <Footer/>
    </div>
   );
}

export default FAQ;