import Card from 'react-bootstrap/Card'
import React from 'react';
import Col from 'react-bootstrap/Col'
import { useEffect } from 'react';
import './Cards.css';
import video1 from '../images/GSR.mp4';
import video2 from '../images/cards2.mp4';
import video3 from '../images/cards3.mp4';
import video4 from '../images/cards4.mp4';

function Cards() {
    useEffect(() => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContext.resume();
    }, []); 

    return (
        <div className='cards1'>
           <div class="card-elements">
                <Col>
                    <Card style={{ width: '18rem' }}>
                        <video src={video1} width='100%' height='40%' autoPlay loop muted playsInline>

                        </video>
                        <Card.Body>

                            <Card.Title>Trusted Organization</Card.Title>
                            <Card.Text>
                            GSR Immigration is committed to provide you with the best client service experience in the industry.
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card style={{ width: '18rem' }}>
                        <video src={video2} width="100%" height="40%" autoPlay loop muted playsInline/>

                        <Card.Body>
                            <Card.Title>Protected By CICC</Card.Title>
                            <Card.Text>
                                Public Interest is saved by ICCRC, the regulatory body, by adhering rules and regulations.
                            </Card.Text>

                        </Card.Body>
                    </Card></Col>

                <Col>
                    <Card style={{ width: '18rem' }}>
                        <video src={video4} height= "290px" width="286px" autoPlay loop muted playsInline/>

                        <Card.Body>
                            <Card.Title>A personalized Approach</Card.Title>
                            <Card.Text>
                                At GSR Immigration, we develop effective immigration strategy for each client separately.
                            </Card.Text>

                        </Card.Body>
                    </Card></Col>
                    <Col>
                    <Card style={{ width: '18rem' }}>
                        <video src={video3} width="100%" height="40%" autoPlay loop muted playsInline/>

                        <Card.Body>
                            <Card.Title>Customer is Valued</Card.Title>
                            <Card.Text>
                                At GSR Immigration, we try to respond our each customer as soon as we receive query.
                            </Card.Text>

                        </Card.Body>
                    </Card></Col>


           </div>
        </div>

    );


}

export default Cards;