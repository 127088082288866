import * as React from 'react';
import './SuccessCarousel.css';
import img1 from '../images/vv-1.jpeg';
import img2 from '../images/vv-2.jpeg';
import img3 from '../images//vv-3.jpeg';
import img4 from '../images/vv-4.jpeg';
import img5 from '../images/vv-5.jpeg';
import img6 from '../images/vv-6.jpeg';
import img7 from '../images/vv-7.jpeg';
import img8 from '../images/vv-8.jpeg';
import img9 from '../images/vv-9.jpeg';
import img10 from '../images/vv-10.jpeg';
import img11 from '../images/vv-11.jpeg';
import img12 from '../images/vv-12.jpeg';
import img13 from '../images/vv-13.jpeg';

export default function QuiltedImageList() {
  return (
   <div className='success-stories-gallery'>
    <div className='first-gallery-text'>
<span className='first-gallery-text'>2000+ Visa approved🎉</span>
    </div>
    <div className='first-gallery'>
    <img src={img1} alt='' className='first-gallery-img'></img>
    <img src={img2} alt='' className='first-gallery-img'></img>
    <img src={img3} alt='' className='first-gallery-img'></img>
    <img src={img4} alt='' className='first-gallery-img'></img>
    <img src={img5} alt='' className='first-gallery-img'></img>
    <img src={img6} alt='' className='first-gallery-img'></img>
    <img src={img7} alt='' className='first-gallery-img'></img>
    <img src={img8} alt='' className='first-gallery-img'></img>
    <img src={img9} alt='' className='first-gallery-img'></img>
    <img src={img10} alt='' className='first-gallery-img'></img>
    <img src={img11} alt='' className='first-gallery-img'></img>
    <img src={img12} alt='' className='first-gallery-img'></img>
    <img src={img13} alt='' className='first-gallery-img'></img>
    <img src={img1} alt='' className='first-gallery-img'></img>
    <img src={img6} alt='' className='first-gallery-img'></img>
    
      
           </div>
   </div>
  );
}

/*
<div className='first-gallery-text'> 
          <h1>1000+</h1>
          <h2> Visitor Visas Approved</h2></div>
 
*/