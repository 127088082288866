import React from 'react';
import './Contact.css';
import Footer from '../Components/Footer';
import Form from '../Components/Forms';

const Contact = () => {
    return (
        <div className='contact'>
             <span className='contact-first-section-heading'>Tell us About you.
                <br></br> We will reach you shortly!</span>
                <Form/>
           
            <Footer/>
        </div>
    );
}
export default Contact;