import React from 'react';
import './navbar.css';
import img from '../images/logo.jpg';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useEffect } from 'react';

function Navbars() {
  
  useEffect(() => {
    const toggleBtn = document.querySelector('.toggle-button');
    const menu = document.querySelector('.menu');
    
    const navbar = document.querySelector('.navbar');
    const handleMenuClick = () => {
      menu.classList.toggle('active');
      navbar.classList.toggle('open');
    };
    
   
 
    toggleBtn.addEventListener('click', handleMenuClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      toggleBtn.removeEventListener('click', handleMenuClick);
    };
  }, []); // Empty dependency array ensures that this effect runs once after the initial render
  useEffect(() => {
    const dropdownButton = document.getElementById('dropdown-basic-button');
    const dropdownItems = document.querySelectorAll('.dropdown-item');
console.log(dropdownButton,dropdownItems)
    const handleMouseOver = () => {
      dropdownButton.style.color = '#005792';
    };

    const handleMouseOut = () => {
      dropdownButton.style.color = 'white';
    };

    dropdownItems.forEach(item => {
      item.addEventListener('mouseover', handleMouseOver);
      item.addEventListener('mouseout', handleMouseOut);
    });

    // Cleanup the event listeners when the component is unmounted or when dropdownItems change
    return () => {
      dropdownItems.forEach(item => {
        item.removeEventListener('mouseover', handleMouseOver);
        item.removeEventListener('mouseout', handleMouseOut);
      });
    };
  }, []); // Empty dependency array ensures that this effect runs once after the initial render

 function handleMenuClick(){
console.log("I am here")

  }

 

    return (

<div class="navbar">
      <div class="navbar-elements">
          <div class="logo">
          <img src={img} class="logo" alt=''></img>
          </div>
          <div class="toggle-button" href="#">
              <span class="toggle-bar"></span>
              <span class="toggle-bar"></span>
              <span class="toggle-bar"></span>
          </div>
          <div class="menu" onClick={handleMenuClick}>
              <ul>
                  <li><a href="/">Home</a></li>
               
                  <li><a href="/about">About</a></li>
                  <li>
                  <DropdownButton id="dropdown-basic-button" title="Services">
      <Dropdown.Item class='dropdown-item' href="/LMIA">LMIA</Dropdown.Item>
      <Dropdown.Item class='dropdown-item' href="/StudyPermit">Study Permit</Dropdown.Item>
      <Dropdown.Item class='dropdown-item' href="/SuperVisa">Super Visa</Dropdown.Item>
      <Dropdown.Item class='dropdown-item' href="/SpousalWorkPermit">Spousal Work Permit</Dropdown.Item>
      <Dropdown.Item class='dropdown-item' href="/SpousalSponsorship">Spousal Sponsorship</Dropdown.Item>
      <Dropdown.Item class='dropdown-item' href="/SpecificWorkPermit">Specific Work Permit</Dropdown.Item>
      
    </DropdownButton>
                    </li>
              <li><a href="/contact">Contact Us</a></li>
              <li><a href="/FAQ">FAQs</a></li>
              </ul>
          </div>
      </div>

      <div id='box'></div>
      <div id='box1'></div>
      <div id='box2'></div>
      <div id='box3'></div>
      <div id='box4'></div>
      <div id='box5'></div>
      <div id='box6'></div>
      <div id='box7'></div>
     
  </div>

   );

    
    
}

export default Navbars;