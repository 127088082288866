import React from 'react';
import './Services.css';
import icon from '../images/icon.png'

function Services(){
    return(
        <div class="service-section">
            <div class="empty-space"></div>
            <div class="service-heading">Our Services</div>
            <div class="empty-space"></div>
<div class="services-list">
    <div class="row1">
        <div class="sub-entry">
<img src={icon} class="img-icon" alt=''></img>
   <div class="sub-entry-col">
   <h4>Study Permit Canada</h4>
        You are invited to be part of the Universities & Colleges under us. We have a wide range of study programs at more than 750+ Universities and Colleges across Canada,
          
   </div>
     </div>
        <div class="sub-entry">
        <img src={icon} class="img-icon" alt=''></img>
        <div class="sub-entry-col">
    
  <h4>Express Entry</h4>
        Apply as a Skilled Worker from Overseas/In Canada; a Tradesperson; or under a Provincial Nominee Program.
     
  </div>
     </div>
        <div class="sub-entry">
        <img src={icon} class="img-icon" alt=''></img>
        <div class="sub-entry-col">
  <h4>Spousal Sponsorship</h4>
    Settle/Re-unite in Canada with your Family. Study or Work together in Canada. Make a future there in Canada.
  
  </div>
     </div>
    </div>
    <div class="row2"> 
    <div class="sub-entry">
    <img src={icon} class="img-icon" alt=''></img>
    <div class="sub-entry-col">
    
  <h4>Visitor Visa</h4>
    Canada is an excellent place to vacation and tour, with its diverse landscapes and climate. Many people come here each year for vacation or to come and spend time with a family members.

  </div>
      </div>
    <div class="sub-entry">
    <img src={icon} class="img-icon" alt=''></img>
    <div class="sub-entry-col">
  <h4>Work Permit</h4>
    Are you a Senior Executive; an IT Specialist; a High/Low Skilled worker? Then you will most likely need some form of Work Permit.
  
  </div>
       </div>
    <div class="sub-entry">
    <img src={icon} class="img-icon" alt=''></img>
    <div class="sub-entry-col">
 <h4>Business/Investor Visa</h4>
    If you are High Net Worth Individual with business acumen, learn how to use your Economic Capital to Immigrate to Canada.
  </div> 
   </div>
   
   </div>
</div>
        </div>
    )
}

export default Services;