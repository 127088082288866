import React from 'react';
import Container from 'react-bootstrap/Container';
import './Information.css';
import officeImage from '../images/gsr.jpg';

function Information() {
    return (
        <div className='info'>
           <div className="image-box">
           <img src={officeImage} alt=''></img>
           </div>
            <div>
            <Container>
                <h4>About GSR Immigration Services</h4>
                <br></br>
                <p>
                    Gurjeet Singh Randhawa (GSR) is one of the most seasoned and renowned Immigration brands globally with a successful experience, since 2015, in providing guidance and processing of Immigrant and non-Immigrant visa applications.
                    We service individuals, families, and corporates.
                    Our core values are an eye for detail, personalized service accompanied by in-time prompt response, patience, transparency, and knowledge sharing.
                </p>
                <p>
                We are Regulated Canadian Immigration Consultants (RCIC) and are in good standing with The Immigration Consultants of Canada Regulatory Council (ICCRC) We operate with the latest information on federal immigration laws and different Provincial Nominee Programs (PNP). We review every client’s profile uniquely and identify the most suitable strategy to achieve their immigration goals with end-to-end support.
                </p>
                <br></br>
                <p>
                GSR Immigration offers variety of Immigration Services to help you settling in Canada. We are experts in all types of  visas - Study, Work Permit, Express Entry, Spousal Sponsorship. We have helped more than 1000 people settle in Canada. We hope you are next!
                </p>
            </Container>
        
            </div>
            </div>
    );
}

export default Information;