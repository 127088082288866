import React from "react";
import './SuperVisa.css';
import AppointmentCard from "./AppointmentCard";
import ServicesCard from "./ServicesCard";
import Footer from '../Components/Footer';
import img from '../images/2.jpg';

function SuperVisa(){
return (
    <div className="supervisa">
         <div className="supervisa-first-image">
         <span className="supervisa-heading">
            Tourist Visa
         </span>
                <img src={img} alt="" className="supervisa-first-img"></img>
             
             </div>
        <span>
        A <b>Temporary Resident Visa (TRV)</b>, also referred to as a visitor visa, is an official document issued by a Canadian visa office that is placed in your passport to show that you have met the requirements for admission to Canada as a temporary resident (either as a visitor, a student, or a worker).
<br></br>
<br></br>
<b>WHAT ARE THE REQUIREMENTS YOU MUST MEET FOR A TRV?</b>
<br></br><br></br>
You must show the officer that you meet the requirements of the Immigration and Refugee Protection Act (IRPA) and the Immigration and Refugee Protection Regulations and that you will be in Canada for a temporary stay.
<br></br>
You must also:

satisfy an officer that you will leave Canada at the end of your stay,
show that you have enough money to maintain yourself and your family members in Canada and to return home,
not intend to work or study in Canada unless authorized to do so,
be law abiding and have no record of criminal activity,
not be a risk to the security of Canada,
provide any additional document requested by the officer to establish your admissibility, and
be in good health (complete a medical examination if required).
        </span>
        <AppointmentCard/>
            <ServicesCard/>
            <Footer/>
    </div>
);
}

export default SuperVisa;