import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Col, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import './Forms.css';
import { useState } from 'react';
import formImg from '../images/form.PNG';

function Forms() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    address: '',
    contact: '',
    visatype: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
   
    emailjs.send('service_ggg9h3s','template_2w18wxq', formData,'NSU1Hnz3yq4HnCuWu')
    //publick-key = NSU1Hnz3yq4HnCuWu
	.then(function(response) {
	   console.log('SUCCESS!', response.status, response.text);
	}, function(err) {
	   console.log('FAILED...', err);
	});
    setFormData({
      name: '',
      email: '',
      message: '',
      address: '',
      contact: '',
      visatype: '',
    });

  };

  return (
    <div className='formbg'>
    <div className='form-section'>
      <div className='form-img-section'>
        <img src={formImg} alt=''></img>
      </div>
     
      <br />
      <br />
      <Form>
      
        <Row className='mb-3'>
          <Form.Group as={Col} controlId='formName'>
            <Form.Label>
              <h6>Name</h6>
            </Form.Label>
            <Form.Control
              placeholder='Full Name'
              value={formData.name}
              onChange={handleChange}
              name='name' // Add name attribute
            />
          </Form.Group>
          <Form.Group as={Col} controlId='formGridEmail'>
            <Form.Label>
              <h6>Email</h6>
            </Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={formData.email}
              onChange={handleChange}
              name='email' // Add name attribute
            />
          </Form.Group>
        </Row>

        <Form.Group className='mb-3' controlId='formGridAddress1'>
          <Form.Label>
            <h6>Address</h6>
          </Form.Label>
          <Form.Control
            placeholder='1234 Main St'
            value={formData.address}
            onChange={handleChange}
            name='address' // Add name attribute
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formGridMessage'>
          <Form.Label>
            <h6>Message</h6>
          </Form.Label>
          <Form.Control
            placeholder='Your Enquiry'
            value={formData.message}
            onChange={handleChange}
            name='message' // Add name attribute
          />
        </Form.Group>

        <Row className='mb-3'>
          <Form.Group as={Col} controlId='formGridCity'>
            <Form.Label>
              <h6>Phone Number</h6>
            </Form.Label>
            <Form.Control
              placeholder='Phone Number'
              value={formData.contact}
              onChange={handleChange}
              name='contact' // Add name attribute
            />
          </Form.Group>

          <Form.Group as={Col} controlId='formGridZip'>
            <Form.Label>
              <h6>Visa Type</h6>
            </Form.Label>
            <Form.Control
              placeholder='Study/Spousal/Tourist'
              value={formData.visatype}
              onChange={handleChange}
              name='visatype' // Add name attribute
            />
          </Form.Group>
        </Row>

        <Button variant='primary' type='submit' onClick={handleSubmit}>
          Submit For Callback
        </Button>
      </Form>

    </div>
        </div>
  );
}

export default Forms;
