import React,{useState} from 'react';
import { Button,Col} from 'react-bootstrap';
import './AppointmentCard.css';
import Calendar from 'react-calendar';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import 'react-calendar/dist/Calendar.css';

function AppointmentCard(){
    const [date, setDate] = useState(new Date());
    const [show, setShow] = useState(false);
    const minDate = new Date();

    const [formData, setFormData] = useState({
        name: '',
        contact: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const onChange = (newDate) => {
      setDate(newDate);
      formData['appointmentDate'] = date;
    };
    function handleAppointmentBooking(){
        if(formData.name.trim()!=='' && formData.contact.trim()!=='' && formData.appointmentDate!==undefined){
          console.log("nothing empty")
          emailjs.send('service_ggg9h3s','template_r6c9zcc', formData,'NSU1Hnz3yq4HnCuWu')
        
        .then(function(response) {
           console.log('SUCCESS!', response.status, response.text);
           setShow(false)
        }, function(err) {
           console.log('FAILED...', err);
        });
        }
        else{
          console.log("nothing empty")
        }
        
    

    }
    return (
        <div className='appointment-card'>
         <span className='appointment-card-heading'>GSR Immigration</span>
         <br></br>
         <br></br>
         <span className='appointment-card-intro'>We provide all Immigration Services</span>
         <div className='appointment-card-details'>
            <span className='appointment-card-detail'>
            <i class="fa fa-map-marker" aria-hidden="true"></i>
                Unit 403-2250 Bovaird Dr E, Brampton ON L6R OW3, Canada</span>
           
           
            <span className='appointment-card-detail'>
            <i class="fa fa-phone" aria-hidden="true"></i>
                Phone: +1(647) 606-6812</span>
          
            <span className='appointment-card-detail'>
            <i class="fa fa-envelope" aria-hidden="true"></i>
                gsimmi@gmail.com</span>
         </div>
         <Button onClick={handleShow}>Book Now</Button>
         <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Book your Appointment with US!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
        <Row className='mb-3'>
          <Form.Group as={Col} controlId='formName'>
            <Form.Label>
              <h6>Name</h6>
            </Form.Label>
            <Form.Control
              placeholder='Full Name'
              value={formData.name}
              onChange={handleChange}
              name='name' // Add name attribute
            />
          </Form.Group>
        </Row>

        <Row className='mb-3'>
          <Form.Group as={Col} controlId='formGridCity'>
            <Form.Label>
              <h6>Phone Number</h6>
            </Form.Label>
            <Form.Control
              placeholder='Phone Number'
              value={formData.contact}
              onChange={handleChange}
              name='contact' // Add name attribute
            />
          </Form.Group>

        </Row>

      </Form>
        <Calendar onChange={onChange} value={date} minDate={minDate} />
        </Modal.Body>
        
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAppointmentBooking}>
            Book 
          </Button>
        </Modal.Footer>
      </Modal>
        
        </div>
    );
}

export default AppointmentCard;