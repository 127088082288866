import React from "react";
import AppointmentCard from "./AppointmentCard";
import './LMIA.css'
import ServicesCard from "./ServicesCard";
import Footer from '../Components/Footer';
import LMIAimg from '../images/LMIA.jpg';

function LMIA(){
    return (
        <div className="LMIA">
           <div className="LMIA-first-image">
                <img src={LMIAimg} alt="" className="LMIA-first-img"></img>
             </div>
           
            <span className="LMIA-text">
            An <b>LMIA</b> stands for <b>“Labour Market Impact Assessment.”</b> It’s a document issued by Employment and Social Development Canada (ESDC) in Canada. An LMIA is required by employers in Canada who want to hire foreign workers on a temporary basis through various work permit programs.

<br>
</br>
<br></br>
The purpose of an <b>LMIA</b> is to assess whether hiring a foreign worker would have a positive or negative impact on the Canadian labor market. The assessment considers factors such as the job’s wages, working conditions, the employer’s efforts to hire Canadian citizens or permanent residents, and whether hiring a foreign worker is justified due to a genuine labor shortage.
<br></br>
<br></br>
If an employer receives a positive <b>LMIA</b>, it means that there is a legitimate need to hire a foreign worker for the specified position because no Canadian citizens or permanent residents are available to fill the job. This positive assessment can be used by the foreign worker to apply for a work permit to work in Canada temporarily.
<br></br>
<br></br>
It’s important to note that the <b>LMIA</b> process is subject to various regulations and guidelines that can change over time, so it’s recommended to refer to the official government sources for the most up-to-date and accurate information if you’re considering hiring or working as a foreign worker in Canada.
            </span>

            <AppointmentCard/>
            <ServicesCard/>
            <Footer/>
        </div>
    );
}

export default LMIA;